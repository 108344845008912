/* eslint-disable max-len */
// @flow

import pdf from '../../../assets/client/rules_of_competition_leading_health_initiative_of_the_year_2024.pdf';

export const FILE_DEFAULT: FileURL = {
	remoteURL: '',
	file: { name: '', url: '' },
	selected: 'remoteURL',
};
export const IS_DEVEL = process.env.NODE_ENV !== 'production';

export const MAKO_ANALYTICS_PAGE_IDS = {
	MAIN: 'HP',
	QUESTION: 'question',
	HELP: 'HelpPage',
	RESULT: 'result',
	SETTING: 'settings',
};

export const SHARE_CONSTANTS = {
	WHATSAPP: 'WhatsApp',
	TWITTER: 'Twitter',
	FACEBOOK: 'Facebook',
};

export const COLLECTIONS = {
	SETTINGS: 'settings',
	LEADS: 'leads',
};

export const PLACEHOLDERS_TEXTS = {
	initiativeName: 'שם היוזמה <span>[עד 60 מילים]</span>',
	firstName: 'שם',
	lastName: 'משפחה',
	organizationName: 'שם הארגון/גוף מעסיק',
	position: 'תפקיד',
	city: 'עיר מגורים',
	phoneNumber1: 'טלפון 1 (ללא מקף)',
	phoneNumber2: 'טלפון 2 (ללא מקף)',
	email: 'דוא"ל',
	initiativeSummary: 'נא לתת תקציר שיסכם את היוזמה בצורה הטובה ביותר <span>[עד 120 מילים]</span>',
	initiativeDescription:
		'נא לפרט אודות היוזמה: מהי, עבור איזה קהל יעד, על כמה אנשים היא תשפיע ובאיזו מידה?</br>כיצד היא תורמת לתחום הבריאות ובמה היא משפרת/משנה את המצב כיום? <span>[עד 1,200 מילים]</span>',
	externalLink1: '1',
	externalLink2: '2',
	externalLink3: '3',
	initiativeTimeEstimation:
		'נא תארו מה צפוי לקרות ליוזמה ואיך היא צפויה להתממש אם תזכו בתחרות. נא התייחסו גם להערכת משך הזמן ומה נדרש לשם יישום היוזמה </br><span>[עד 120 מילים]</span>',
	initativeStart: 'מתי התחילה? האם עדיין נמשכת? <span>[עד 120 מילים]</span>',
	personDescription:
		'נא תארו את הדמות/הצוות המוביל/יוביל את היוזמה ונסיונה/ם בהובלת מיזמים דומים <span>[עד 120 מילים]</span>',
};

export const CHECKBOXES_LABELS = {
	checkbox1: ` <span>הסכמה לתנאי התחרות</span> |  יש להסכים <a href="${pdf}" rel="noopener noreferrer" target="_blank">לתנאי התקנון</a>, לרבות ויתור על סודיות ואישור שימוש בחומרים, כחלק מהגשת המועמדות. הגשות יתקבלו עד ה-15.9.24 בחצות.`,
	checkbox2: `<span>הסכמה ללוחות זמנים</span> | יש להסכים לשריין מראש את התאריכים (ליוזמות שיגיעו לגמר): 31.10.24 (הצגת היוזמה) 05.11.24 (להכרזה על הזכייה, קבלת הפרס וצילום לסיקור ב'ידיעות אחרונות' ו-ynet)`,
};

export const ERRORS_TEXTS = {
	initiativeName: `<span>נדרש למלא את שם היוזמה</span>`,
	initiativeNameMaxLength: `<span>שם היוזמה מוגבל עד ל60 מילים</span>`,
	initiativeType: `<span>נדרש לבחור את סוג היוזמה</span>`,
	submitInitiativeAs: `<span>נדרש לבחור בשם מי את/ה מגיש/ה את היוזמה</span>`,
	firstName: `<span>נדרש למלא שם</span>`,
	lastName: `<span>נדרש למלא שם משפחה</span>`,
	organizationName: `<span>נדרש למלא את שם הארגון/גוף מעסיק</span>`,
	position: `<span>נדרש למלא תפקיד</span>`,
	city: `<span>נדרש למלא עיר מגורים</span>`,
	phoneNumber1: `<span>נדרש למלא מספר טלפון</span>`,
	email: `<span>נדרש למלא דוא"ל</span>`,
	initiativeSummary: `<span>נדרש למלא את תקציר היוזמה</span>`,
	initiativeSummaryMaxLength: `<span>תקציר היוזמה מוגבל עד ל120 מילים</span>`,
	initiativeDescription: `<span>נדרש למלא את תיאור היוזמה</span>`,
	initiativeDescriptionMaxLength: `<span>תיאור היוזמה מוגבל עד ל1200 מילים</span>`,
	statusAndSchedules: `<span>נדרש לבחור סטטוס ולוחות זמנים</span>`,
	initiativeMaturityLevel: `<span>נדרש לבחור את רמת הבשלות של היוזמה</span>`,
	initiativeTimeEstimation: `<span>נדרש למלא מה צפוי לקרות ליוזמה</span>`,
	initiativeTimeEstimationMaxLength: `<span>סטטוס ולוחות זמנים מוגבל עד ל120 מילים</span>`,
	initativeStart: `<span>נדרש למלא מתי התחילה היוזמה והאם עדיין נמשכת</span>`,
	executorOfIinitiative: `<span>נדרש לבחור מי יוציא לפועל</span>`,
	executorOfIinitiativeMaxLength: `<span>מי יוציא לפועל? (הצוות)  מוגבל עד ל120 מילים	</span>`,
	personDescription: `<span>נדרש לתאר את הדמות/צוות המוביל/יוביל את היוזמה</span>`,
	lastRadiobuttonsSection: `<span>נדרש למלא שדות השפעה ותרומה</span>`,
	checkbox1: `<span>נדרש לאשר הסכמה לתנאי התחרות</span>`,
	checkbox2: `<span>נדרש לאשר הסכמה ללוחות הזמנים</span>`,
	recaptchaError: `<span>עלייך לאשר שאינך רובוט</span>`,
};

export const RADIOBUTTONS_VALUES = {
	initiativeType: {
		first: 'רעיון', // 'idea',
		second: 'פעילות', // 'activity',
		third: 'תכנית/פרויקט', // 'program/project',
		fourth: 'שירות/מוצר/פיתוח', // 'service/product/development',
		fifth: 'אחר', // 'other',
	},
	submitInitiativeAs: {
		first: ' גוף, מוסד או ארגון במערכת הבריאות הציבורית', // 'organization inside the health system',
		second: 'חברה במגזר הפרטי', // 'private sector company',
		third: 'מוסד חינוכי/אקדמי', // 'educational / academic institution',
		fourth: 'עמותה או ארגון ללא מטרות רווח', // 'non profit organization',
		fifth: 'בעצמי (אדם פרטי)', // 'private person',
	},
	initiativeMaturityLevel: {
		first: 'שלב היגוי', // 'Steering stage',
		second: 'פרויקט בשל להתנסות', // 'Project due to experiment',
		third: 'פרויקט שנוסה ובשל ליישום', // 'A tried and tested project',
		fourth: 'פרויקט מיושם', // 'Project implemented',
		fifth: 'היוזמה בתהליך', //
	},
	statusAndSchedules: {
		first: 'זה עדיין בגדר רעיון', // 'Still just an idea',
		second: 'היוזמה בתהליך', // 'Initiative already started',
	},
	executorOfIinitiative: {
		first: 'יש כבר תוכנית', // 'There is already a plan',
		second: 'אני מעוניין/ת להוביל/לקחת חלק בהובלת היוזמה. אבל לא התחלתי עדיין', // "I wish to start the initiative, but hasn't started yet",
		third: 'זה רק רעיון ואיני מעוניין/ת להוביל/לקחת חלק ביוזמה, אלא רק להגישה כהצעה לחבר השופטים', // 'This is just an idea and I dont  want to do it, just to give it to the competition',
	},
	initiativeWillEffectTheLife: {
		first: 'במידה מועטה', // 'not a lot',
		second: 'במידה בינונית', // 'will have some effect',
		third: 'במידה רבה', // 'very much',
	},
	initiativeWillEffectTheHealth: {
		first: 'במידה מועטה', // 'not a lot',
		second: 'במידה בינונית', // 'will have some effect',
		third: 'במידה רבה', // 'very much',
	},
	initiativeWillHelpBridge: {
		first: 'במידה מועטה', // 'not a lot',
		second: 'במידה בינונית', // 'will have some effect',
		third: 'במידה רבה', // 'very much',
	},
};
