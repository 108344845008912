// @flow

import * as React from 'react';
import classNames from 'classnames';

import ynetLogoSrc from 'assets/client/images/logo.svg';
import rebootLogoSrc from 'assets/client/images/reboot-logo.png';
import AccessibilityIcon from '../AccessibilityIcon';

import Socials from '../Socials';
import * as css from './Header.scss';

type Props = {
	className?: string,
	data?: any,
	disableLogo?: boolean,
	fixed?: boolean,
	isLogoShow?: boolean,
};

const Header = ({ data, className, disableLogo, fixed, isLogoShow }: Props) => {
	const ynetLink = 'https://www.ynet.co.il/';
	const rebootLogoLink = 'https://www.reboot.org.il/';

	return (
		<div className={classNames(css.header, className, fixed && css.fixed, isLogoShow && css.showLogo)} id="header">
			<div className={css.headerWrapper}>
				<div className={css.rightSide}>
					<a href={ynetLink} className={css.ynetLogo} rel="noopener noreferrer" target="_blank">
						<img src={ynetLogoSrc} alt="ynet-logo" />
					</a>
				</div>
				<div className={css.leftSide}>
					<div className={css.sociallsWrapper}>
						<Socials />
						<AccessibilityIcon />
					</div>
					<div className={css.logo}>
						<a href={rebootLogoLink} target="_blank" rel="noreferrer">
							<img src={rebootLogoSrc} alt="rebbot-logo" />
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

Header.defaultProps = {
	className: '',
	data: {},
	disableLogo: false,
	fixed: false,
	isLogoShow: true,
};

export { Header };
