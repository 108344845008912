// @flow
import { connect } from 'react-redux';

// import { logout } from 'client/actions';
import Intro from './Intro';

const mapState = (state: ClientStore) => ({
	settings: state.settings.item,
});
const mapDispatch = {};

export default connect(mapState, mapDispatch)(Intro);
