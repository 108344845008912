// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Header, Footer, Responsive } from 'client/components/common';
import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';

import bg from 'assets/client/images/content-bg.jpg';
import bgMob from 'assets/client/images/content-bg-mob.jpg';

import ContentForm from 'client/components/common/ContentForm';
// import ClosedActivityPopup from 'client/components/common/Popups/ClosedActivityPopup';
import AlreadyInSystemPopup from 'client/components/common/Popups/AlreadyInSystemPopup';
// import WaitingPopup from 'client/components/common/Popups/WaitingPopup';
import * as css from './Intro.scss';

// import { dataLayerEvents } from 'common/utils/gtag';

type Props = {|
	...EnhancedProps,
	className?: string,
	in: boolean,
	// match?: RouterMatch,
	settings: SettingsItemType,
|};

type State = {
	isShowAlreadyInSystemPopup: boolean,
};

type ReactObjRef = { current: HTMLDivElement | null };

class Intro extends React.PureComponent<Props, State> {
	transitionCompleteTimeout: TimeoutID;
	sliderRef: ReactObjRef;

	static defaultProps = {
		className: '',
		// match: {},
	};

	state = {
		isShowAlreadyInSystemPopup: false,
	};

	constructor(props: Props) {
		super(props);

		this.sliderRef = React.createRef();
	}

	componentDidMount() {
		const { settings } = this.props;
		const isEventOver = _.get(settings, 'isEventOver', false);
		const isCreatedLead = localStorage.getItem('rebbotLeadCreatedV3');

		if (isCreatedLead && !isEventOver) {
			this.setState({
				isShowAlreadyInSystemPopup: true,
			});
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.in !== prevProps.in) {
			const { onTransitionComplete } = this.props;
			let duration = parseInt(this.props.in ? css.transitionIn : css.transitionOut, 10);

			if (!this.props.in) {
				duration = 0;
			}
			clearTimeout(this.transitionCompleteTimeout);
			this.transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}
	}

	componentWillUnmount() {}

	render() {
		const { className, transitionState, settings } = this.props;
		const { isShowAlreadyInSystemPopup } = this.state;

		const isMobile = Responsive.isMatching(Responsive.MOBILE);
		const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
		const isTabletL = Responsive.isMatching(Responsive.TABLET_L);
		const isTabletP = Responsive.isMatching(Responsive.TABLET_P);

		const blueTitle = _.get(settings, 'topBlueTitle', '');
		const greenTitle = _.get(settings, 'topGreenTitle', '');
		const topText = _.get(settings, 'topText', '');
		const coloredSubtitle = _.get(settings, 'topColoredSubtitle', '');
		const convertedColoredSubtitle = coloredSubtitle.replace('[', '<span>').replace(']', '</span>');
		const smallSubtitle = _.get(settings, 'topSmallSubtitle', '');
		const footerTextOne = _.get(settings, 'footerTextOne', '');
		const footerTextTwo = _.get(settings, 'footerTextTwo', '');

		// const isEventOver = _.get(settings, 'isEventOver', false);
		// console.info('settings', settings);

		return (
			<div className={classNames(css.intro, css[transitionState], className)}>
				<Header />
				<div className={css.content}>
					{(isDesktop || isTabletL || isTabletP) && (
						<div className={css.contentBg} style={{ backgroundImage: `url(${bg})` }}></div>
					)}
					{isMobile && <div className={css.contentBgMob} style={{ backgroundImage: `url(${bgMob})` }}></div>}
					<div className={css.contentWrapper}>
						<div className={css.blueTitle}>
							<div className={css.blueTitleWrapper}>{blueTitle}</div>
						</div>
						<div className={css.greenTitle}>
							<div className={css.greenTitleWrapper}>{greenTitle}</div>
						</div>
						<div className={css.mainContent}>
							<div className={css.topText} dangerouslySetInnerHTML={{ __html: topText }}></div>
							<div className={css.subtitles}>
								<p dangerouslySetInnerHTML={{ __html: convertedColoredSubtitle }}></p>
								<p>{smallSubtitle}</p>
							</div>
							<ContentForm />
						</div>
					</div>
				</div>
				<Footer footerTextOne={footerTextOne} footerTextTwo={footerTextTwo} />
				<AlreadyInSystemPopup className={isShowAlreadyInSystemPopup ? 'show' : ''} />
				{/* <ClosedActivityPopup className="show" /> */}
				{/* <WaitingPopup /> */}
			</div>
		);
	}
}

export default withTransition(Intro);
