/* eslint-disable yoda */
/* eslint-disable no-else-return */
/* eslint-disable dot-notation */
/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
// @flow
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import _ from 'lodash';
import * as React from 'react';
import classNames from 'classnames';

import { LEAD_CONSTANTS } from 'common/constants';
import Icon from 'common/components/Icon';

import {
	PLACEHOLDERS_TEXTS,
	CHECKBOXES_LABELS,
	COLLECTIONS,
	ERRORS_TEXTS,
	RADIOBUTTONS_VALUES,
} from 'client/constants';
import { Form, TextField, Radio, Checkbox, MediaField, Button } from 'client/components/common';
import { initialize, SubmissionError } from 'redux-form';
import { createItem } from 'client/actions';
import { dataLayerEvents } from 'common/utils/gtag';
import ErrorsPopup from '../Popups/ErrorsPopup';
import SendedFormPopup from '../Popups/SendedFormPopup';
import AlreadyInSystemPopup from '../Popups/AlreadyInSystemPopup';
import FilesSizeLimitPopup from '../Popups/FilesSizeLimitPopup';
import * as css from './ContentForm.scss';

type FormData = {
	initiativeName?: string,
	initiativeType?: string,
	submitInitiativeAs?: string,
	firstName?: string,
	lastName?: string,
	organizationName?: string,
	position?: string,
	city?: string,
	phoneNumber1?: string,
	phoneNumber2?: string,
	email?: string,
	initiativeSummary?: string,
	initiativeDescription?: string,
	initiativeMaturityLevel?: string,
	initiativeTimeEstimation?: string,
	initiativeTimeEstimationInProgress?: string,
	personDescription?: string,
	initiativeWillEffectTheLife?: string,
	initiativeWillEffectTheHealth?: string,
	initiativeWillHelpBridge?: string,
	file1?: FileURL,
	file2?: FileURL,
	file3?: FileURL,
	externalLink1?: string,
	externalLink2?: string,
	externalLink3?: string,
	checkbox1?: boolean,
	checkbox2?: boolean,
};

type Props = {
	values: FormData,
	errors: FormData,
	createItem: typeof createItem,
};

type State = {
	isUserNotified: boolean,
	isShowSenededFormPopup: boolean,
	isShowAlreadyInSystemPopup: boolean,
	fileSize1: number,
	fileSize2: number,
	fileSize3: number,
	isShowFilesSizeLimitPopup: boolean,
	recaptchaSolved: boolean,
	recaptchaError: string,
	isLoading: boolean,
};

const maxLength = (value: string, maxWordsLength: number) => {
	console.info('VALUE', value);
	console.info(
		'LENGTH: ',
		value
			.toString()
			.trim()
			.split(' ').length
	);
	// return value.toString().trim().length > maxWordsLength + 1;
	if (value === undefined) return 0 > maxWordsLength + 1;
	else {
		return (
			value
				.toString()
				.trim()
				.split(' ').length > maxWordsLength
		);
	}
};

const FORM_CONFIG = {
	form: 'userInfo',
	// validate: values => {
	// 	const errors = {};
	// 	if (!values.initiativeName) {
	// 		errors.initiativeName = `שם היוזמה – <span>נדרש למלא את שם היוזמה</span>`;
	// 	}
	// 	return errors;
	// },
};

class ContentForm extends React.PureComponent<Props, State> {
	static defaultProps = {
		// className: '',
	};

	constructor(props: Props) {
		super(props);
		this.state = {
			isUserNotified: false,
			isShowSenededFormPopup: false,
			isShowAlreadyInSystemPopup: false,
			fileSize1: 0,
			fileSize2: 0,
			fileSize3: 0,
			isShowFilesSizeLimitPopup: false,
			recaptchaSolved: false,
			isLoading: false,
			recaptchaError: '',
		};
	}

	componentDidMount() {
		window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
			size: 'normal',
			callback: response => {
				this.setState({ recaptchaSolved: true, recaptchaError: '' });
				console.info('reCAPTCHA solved');
			},
		});

		window.recaptchaVerifier.render();
	}

	initFormValues = async () => {};

	onFormSubmit = async (values: FormData) => {
		this.setState({
			isUserNotified: false,
			isLoading: true,
		});

		const errorsObj = {};
		const phoneRegExp = /^\+?(972|0)(\-)?0?(([23489]{1}\d{7})|[5]{1}\d{8})$/;
		const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!values.initiativeName) {
			errorsObj.initiativeName = ERRORS_TEXTS.initiativeName;
		}

		if (values.initiativeName && maxLength(values.initiativeName, 60)) {
			errorsObj.initiativeName = ERRORS_TEXTS.initiativeNameMaxLength;
		}

		if (!values.initiativeType) {
			errorsObj.initiativeType = ERRORS_TEXTS.initiativeType;
		}

		if (!values.submitInitiativeAs) {
			errorsObj.submitInitiativeAs = ERRORS_TEXTS.submitInitiativeAs;
		}

		if (!values.firstName) {
			errorsObj.firstName = ERRORS_TEXTS.firstName;
		}

		if (!values.lastName) {
			errorsObj.lastName = ERRORS_TEXTS.lastName;
		}

		if (!values.organizationName) {
			errorsObj.organizationName = ERRORS_TEXTS.organizationName;
		}

		if (!values.position) {
			errorsObj.position = ERRORS_TEXTS.position;
		}

		if (!values.city) {
			errorsObj.city = ERRORS_TEXTS.city;
		}

		if (!values.phoneNumber1) {
			errorsObj.phoneNumber1 = ERRORS_TEXTS.phoneNumber1;
		}

		if (values.phoneNumber1) {
			const validPhone = phoneRegExp.test(values.phoneNumber1.toLocaleLowerCase());
			if (!validPhone) {
				errorsObj.phoneNumber1 = ERRORS_TEXTS.phoneNumber1;
			}
		}

		if (!values.email) {
			errorsObj.email = ERRORS_TEXTS.email;
		}

		if (values.email) {
			const validEmail = emailRegExp.test(values.email.toLocaleLowerCase());
			if (!validEmail) {
				errorsObj.email = ERRORS_TEXTS.email;
			}
		}

		if (!values.initiativeSummary) {
			errorsObj.initiativeSummary = ERRORS_TEXTS.initiativeSummary;
		}

		if (values.initiativeSummary && maxLength(values.initiativeSummary, 120)) {
			console.info(maxLength(values.initiativeSummary, 120));
			errorsObj.initiativeSummary = ERRORS_TEXTS.initiativeSummaryMaxLength;
		}

		if (!values.initiativeDescription) {
			errorsObj.initiativeDescription = ERRORS_TEXTS.initiativeDescription;
		}

		if (values.initiativeDescription && maxLength(values.initiativeDescription, 1200)) {
			errorsObj.initiativeDescription = ERRORS_TEXTS.initiativeDescriptionMaxLength;
		}

		if (!values.initiativeMaturityLevel) {
			errorsObj.initiativeMaturityLevel = ERRORS_TEXTS.initiativeMaturityLevel;
		}

		if (values.initiativeMaturityLevel) {
			const initiativeMaturityLevel = _.get(values, 'initiativeMaturityLevel', '');
			if (
				(initiativeMaturityLevel === RADIOBUTTONS_VALUES.initiativeMaturityLevel.first ||
					initiativeMaturityLevel === RADIOBUTTONS_VALUES.initiativeMaturityLevel.second ||
					initiativeMaturityLevel === RADIOBUTTONS_VALUES.initiativeMaturityLevel.third ||
					initiativeMaturityLevel === RADIOBUTTONS_VALUES.initiativeMaturityLevel.fourth) &&
				!values.initiativeTimeEstimation
			) {
				errorsObj.initiativeTimeEstimation = ERRORS_TEXTS.initiativeTimeEstimation;
			}

			if (
				initiativeMaturityLevel === RADIOBUTTONS_VALUES.initiativeMaturityLevel.fifth &&
				!values.initiativeTimeEstimationInProgress
			) {
				errorsObj.initiativeTimeEstimationInProgress = ERRORS_TEXTS.initiativeTimeEstimation;
			}
		}

		if (values.initiativeTimeEstimation && maxLength(values.initiativeTimeEstimation, 120)) {
			errorsObj.initiativeTimeEstimation = ERRORS_TEXTS.initiativeTimeEstimationMaxLength;
		}

		if (values.initiativeTimeEstimationInProgress && maxLength(values.initiativeTimeEstimationInProgress, 120)) {
			errorsObj.initiativeTimeEstimationInProgress = ERRORS_TEXTS.initiativeTimeEstimationMaxLength;
		}

		if (!values.personDescription) {
			errorsObj.personDescription = ERRORS_TEXTS.personDescription;
		}
		if (values.personDescription && maxLength(values.personDescription, 120)) {
			errorsObj.personDescription = ERRORS_TEXTS.executorOfIinitiativeMaxLength;
		}

		if (
			!values.initiativeWillEffectTheLife ||
			!values.initiativeWillEffectTheHealth ||
			!values.initiativeWillHelpBridge
		) {
			errorsObj.lastRadiobuttonsSection = ERRORS_TEXTS.lastRadiobuttonsSection;
		}

		if (!values.initiativeWillEffectTheLife) {
			errorsObj.initiativeWillEffectTheLife = ERRORS_TEXTS.lastRadiobuttonsSection;
		}
		if (!values.initiativeWillEffectTheHealth) {
			errorsObj.initiativeWillEffectTheHealth = ERRORS_TEXTS.lastRadiobuttonsSection;
		}
		if (!values.initiativeWillHelpBridge) {
			errorsObj.initiativeWillHelpBridge = ERRORS_TEXTS.lastRadiobuttonsSection;
		}

		if (!values.checkbox1) {
			errorsObj.checkbox1 = ERRORS_TEXTS.checkbox1;
		}

		if (!values.checkbox2) {
			errorsObj.checkbox2 = ERRORS_TEXTS.checkbox2;
		}

		if (!this.state.recaptchaSolved) {
			this.setState({ recaptchaError: ERRORS_TEXTS.recaptchaError });
		}

		if (!_.isEmpty(errorsObj)) {
			const errosWithoutCheckboxes = _.omit(errorsObj, ['checkbox1', 'checkbox2']);
			if (errorsObj.checkbox1 || errorsObj.checkbox2) {
				dataLayerEvents.onFormSended('Fail - חסרה הסכמה בצ’קבוקס');
				this.setState({
					isLoading: false,
				});
			}

			if (_.size(errosWithoutCheckboxes) > 0) {
				dataLayerEvents.onFormSended('Fail - חסרים פרטים');
				this.setState({
					isLoading: false,
				});
			}

			throw new SubmissionError(errorsObj);
		}

		if (_.isEmpty(errorsObj) && this.state.recaptchaSolved) {
			const leadResult = await this.props.createItem<any>({ collection: COLLECTIONS.LEADS, data: values });

			if (leadResult.lead === LEAD_CONSTANTS.FOUNDED) {
				this.setState({
					isShowAlreadyInSystemPopup: true,
					isLoading: false,
				});
				dataLayerEvents.onFormSended('Success');
				localStorage.setItem('rebbotLeadCreatedV3', 'true');
			}

			if (leadResult.lead === LEAD_CONSTANTS.CREATED) {
				this.setState({
					isShowSenededFormPopup: true,
					isLoading: false,
				});
				dataLayerEvents.onFormSended('Success');
				localStorage.setItem('rebbotLeadCreatedV3', 'true');
			}
		}
	};

	onSetFileSize1 = val => {
		this.setState({
			fileSize1: val,
		});
	};

	onSetFileSize2 = val => {
		this.setState({
			fileSize2: val,
		});
	};

	onSetFileSize3 = val => {
		this.setState({
			fileSize3: val,
		});
	};

	closeErrorPopup = () => {
		this.setState({
			isUserNotified: true,
		});
	};

	closeFilesSizeLimitPopup = () => {
		this.setState({
			isShowFilesSizeLimitPopup: false,
		});
	};

	showFilesSizeLimitPopup = () => {
		this.setState({
			isShowFilesSizeLimitPopup: true,
		});
	};

	render() {
		const { values, errors } = this.props;

		const {
			isUserNotified,
			isShowSenededFormPopup,
			isShowAlreadyInSystemPopup,
			fileSize1,
			fileSize2,
			fileSize3,
			isShowFilesSizeLimitPopup,
			recaptchaSolved,
			recaptchaError,
		} = this.state;

		const initiativeMaturityLevel = _.get(values, 'initiativeMaturityLevel', '');
		const err = !recaptchaSolved && recaptchaError !== '' ? { recaptchaError } : null;
		const errorsList = { ...errors, ...err };
		const neededErrorsList = _.omit(errorsList, [
			'initiativeWillEffectTheLife',
			'initiativeWillEffectTheHealth',
			'initiativeWillHelpBridge',
		]);

		const initiativeNameError = _.get(errors, 'initiativeName', '');
		const initiativeTypeError = _.get(errors, 'initiativeType', '');
		const submitInitiativeAsError = _.get(errors, 'submitInitiativeAs', '');
		const firstNameError = _.get(errors, 'firstName', '');
		const lastNameError = _.get(errors, 'lastName', '');
		const organizationNameError = _.get(errors, 'organizationName', '');
		const positionError = _.get(errors, 'position', '');
		const cityError = _.get(errors, 'city', '');
		const phoneNumber1Error = _.get(errors, 'phoneNumber1', '');
		const emailError = _.get(errors, 'email', '');
		const initiativeSummaryError = _.get(errors, 'initiativeSummary', '');
		const initiativeDescriptionError = _.get(errors, 'initiativeDescription', '');
		const initiativeMaturityLevelError = _.get(errors, 'initiativeMaturityLevel', '');
		const initiativeTimeEstimationError = _.get(errors, 'initiativeTimeEstimation', '');
		const initiativeTimeEstimationInProgress = _.get(errors, 'initiativeTimeEstimationInProgress', '');
		const personDescriptionError = _.get(errors, 'personDescription', '');

		const initiativeWillEffectTheLifeError = _.get(errors, 'initiativeWillEffectTheLife', '');
		const initiativeWillEffectTheHealthError = _.get(errors, 'initiativeWillEffectTheHealth', '');
		const initiativeWillHelpBridgeError = _.get(errors, 'initiativeWillHelpBridge', '');
		const checkbox1Error = _.get(errors, 'checkbox1', '');
		const checkbox2Error = _.get(errors, 'checkbox2', '');

		const wordCount = (value, limit) => {
			if (value === undefined) return 0;
			return value.trim().split(' ').length;
		};

		return (
			<div className={css.formWrapper}>
				<Form className={css.form} config={FORM_CONFIG} onSubmit={this.onFormSubmit}>
					<div className={css.formRow}>
						<div className={classNames(css.fieldWrapper, initiativeNameError && css.error)}>
							<TextField
								name="initiativeName"
								placeholder={PLACEHOLDERS_TEXTS.initiativeName}
								maxWordsLength={60}
								isTextarea
								textareaType={4}
							/>
							<span
								style={{
									fontSize: '1.3em',
									paddingRight: '0.5vw',
									color: wordCount(this.props.values['initiativeName']) > 60 ? 'red' : '',
								}}
							>
								{wordCount(this.props.values['initiativeName'])} / 60
							</span>
						</div>
					</div>
					<div className={css.formRow}>
						<div className={css.rowTitle}>סוג היוזמה</div>
						<div className={css.radioBtnsWrapper}>
							<div className={css.fieldWrapper}>
								<Radio
									name="initiativeType"
									value={RADIOBUTTONS_VALUES.initiativeType.first}
									label="רעיון"
									error={initiativeTypeError}
								/>
							</div>
							<div className={css.fieldWrapper}>
								<Radio
									name="initiativeType"
									value={RADIOBUTTONS_VALUES.initiativeType.second}
									label="פעילות"
									error={initiativeTypeError}
								/>
							</div>
							<div className={css.fieldWrapper}>
								<Radio
									name="initiativeType"
									value={RADIOBUTTONS_VALUES.initiativeType.third}
									label="תכנית/פרויקט"
									error={initiativeTypeError}
								/>
							</div>
							<div className={css.fieldWrapper}>
								<Radio
									name="initiativeType"
									value={RADIOBUTTONS_VALUES.initiativeType.fourth}
									label="שירות/מוצר/פיתוח"
									error={initiativeTypeError}
								/>
							</div>
							<div className={css.fieldWrapper}>
								<Radio
									name="initiativeType"
									value={RADIOBUTTONS_VALUES.initiativeType.fifth}
									label="אחר"
									error={initiativeTypeError}
								/>
							</div>
						</div>
					</div>
					<div className={css.formRow}>
						<div className={css.rowTitle}>אני מגיש/ה את היוזמה בשם:</div>
						<div className={css.radioBtnsWrapper}>
							<div className={css.fieldWrapper}>
								<Radio
									name="submitInitiativeAs"
									value={RADIOBUTTONS_VALUES.submitInitiativeAs.first}
									label=" גוף, מוסד או ארגון במערכת הבריאות הציבורית"
									error={submitInitiativeAsError}
								/>
							</div>
							<div className={css.fieldWrapper}>
								<Radio
									name="submitInitiativeAs"
									value={RADIOBUTTONS_VALUES.submitInitiativeAs.second}
									label="חברה במגזר הפרטי"
									error={submitInitiativeAsError}
								/>
							</div>
							<div className={css.fieldWrapper}>
								<Radio
									name="submitInitiativeAs"
									value={RADIOBUTTONS_VALUES.submitInitiativeAs.third}
									label="מוסד חינוכי/אקדמי"
									error={submitInitiativeAsError}
								/>
							</div>
							<div className={css.fieldWrapper}>
								<Radio
									name="submitInitiativeAs"
									value={RADIOBUTTONS_VALUES.submitInitiativeAs.fourth}
									label="עמותה או ארגון ללא מטרות רווח"
									error={submitInitiativeAsError}
								/>
							</div>
							<div className={css.fieldWrapper}>
								<Radio
									name="submitInitiativeAs"
									value={RADIOBUTTONS_VALUES.submitInitiativeAs.fifth}
									label="בעצמי (אדם פרטי)"
									error={submitInitiativeAsError}
								/>
							</div>
						</div>
					</div>
					<div className={classNames(css.formRow, css.multipleInputs)}>
						<div className={css.rowTitle}>פרטי המגיש/ה</div>
						<div className={css.fieldsWrapper}>
							<div className={classNames(css.fieldWrapper, css.shortField, firstNameError && css.error)}>
								<TextField name="firstName" placeholder={PLACEHOLDERS_TEXTS.firstName} />
							</div>
							<div className={classNames(css.fieldWrapper, css.shortField, lastNameError && css.error)}>
								<TextField name="lastName" placeholder={PLACEHOLDERS_TEXTS.lastName} />
							</div>
							<div
								className={classNames(
									css.fieldWrapper,
									css.shortField,
									organizationNameError && css.error
								)}
							>
								<TextField name="organizationName" placeholder={PLACEHOLDERS_TEXTS.organizationName} />
							</div>
							<div className={classNames(css.fieldWrapper, css.shortField, positionError && css.error)}>
								<TextField name="position" placeholder={PLACEHOLDERS_TEXTS.position} />
							</div>
							<div className={classNames(css.fieldWrapper, css.shortField, cityError && css.error)}>
								<TextField name="city" placeholder={PLACEHOLDERS_TEXTS.city} />
							</div>
							<div
								className={classNames(css.fieldWrapper, css.shortField, phoneNumber1Error && css.error)}
							>
								<TextField name="phoneNumber1" placeholder={PLACEHOLDERS_TEXTS.phoneNumber1} />
							</div>
							<div className={classNames(css.fieldWrapper, css.shortField)}>
								<TextField name="phoneNumber2" placeholder={PLACEHOLDERS_TEXTS.phoneNumber2} />
							</div>
							<div className={classNames(css.fieldWrapper, css.shortField, emailError && css.error)}>
								<TextField name="email" placeholder={PLACEHOLDERS_TEXTS.email} />
							</div>
						</div>
					</div>
					<div className={classNames(css.formRow)}>
						<div className={css.rowTitle}>תקציר היוזמה</div>
						<div className={classNames(css.fieldWrapper, initiativeSummaryError && css.error)}>
							<TextField
								name="initiativeSummary"
								placeholder={PLACEHOLDERS_TEXTS.initiativeSummary}
								isTextarea
								textareaType={1}
								maxWordsLength={120}
							/>
							<span
								style={{
									fontSize: '1.3em',
									paddingRight: '0.5vw',
									color: wordCount(this.props.values['initiativeSummary']) > 120 ? 'red' : '',
								}}
							>
								{wordCount(this.props.values['initiativeSummary'])} / 120
							</span>
						</div>
					</div>
					<div className={classNames(css.formRow)}>
						<div className={css.rowTitle}>תיאור היוזמה</div>
						<div className={classNames(css.fieldWrapper, initiativeDescriptionError && css.error)}>
							<TextField
								name="initiativeDescription"
								placeholder={PLACEHOLDERS_TEXTS.initiativeDescription}
								isTextarea
								textareaType={2}
								maxWordsLength={1200}
							/>
							<span
								style={{
									fontSize: '1.3em',
									paddingRight: '0.5vw',
									color: wordCount(this.props.values['initiativeDescription']) > 1200 ? 'red' : '',
								}}
							>
								{wordCount(this.props.values['initiativeDescription'])} / 1200
							</span>
						</div>
					</div>
					<div className={classNames(css.formRow, css.statusAndSchedules)}>
						<div className={css.rowTitle}>סטטוס ולוחות זמנים</div>
						<div className={classNames(css.radioBtnsWrapper, css.radioBtnsWrapperWithTitle)}>
							<div className={css.radioBtnsWrapperTitle}>נסה להגדיר את רמת הבשלות של היוזמה:</div>
							<div className={css.fieldWrapper}>
								<Radio
									name="initiativeMaturityLevel"
									value={RADIOBUTTONS_VALUES.initiativeMaturityLevel.first}
									label="שלב היגוי"
									error={initiativeMaturityLevelError}
								/>
							</div>
							<div className={css.fieldWrapper}>
								<Radio
									name="initiativeMaturityLevel"
									value={RADIOBUTTONS_VALUES.initiativeMaturityLevel.second}
									label="פרויקט בשל להתנסות"
									error={initiativeMaturityLevelError}
								/>
							</div>
							<div className={css.fieldWrapper}>
								<Radio
									name="initiativeMaturityLevel"
									value={RADIOBUTTONS_VALUES.initiativeMaturityLevel.third}
									label="פרויקט שנוסה ובשל ליישום"
									error={initiativeMaturityLevelError}
								/>
							</div>
							<div className={css.fieldWrapper}>
								<Radio
									name="initiativeMaturityLevel"
									value={RADIOBUTTONS_VALUES.initiativeMaturityLevel.fourth}
									label="פרויקט מיושם"
									error={initiativeMaturityLevelError}
								/>
							</div>
						</div>
						<div className={css.fieldsWrapper}>
							<div
								className={classNames(
									css.fieldWrapper,
									initiativeTimeEstimationError &&
										(initiativeMaturityLevel ===
											RADIOBUTTONS_VALUES.initiativeMaturityLevel.first ||
											initiativeMaturityLevel ===
												RADIOBUTTONS_VALUES.initiativeMaturityLevel.second ||
											initiativeMaturityLevel ===
												RADIOBUTTONS_VALUES.initiativeMaturityLevel.third ||
											initiativeMaturityLevel ===
												RADIOBUTTONS_VALUES.initiativeMaturityLevel.fourth) &&
										css.error
								)}
							>
								<TextField
									name="initiativeTimeEstimation"
									placeholder={PLACEHOLDERS_TEXTS.initiativeTimeEstimation}
									isTextarea
									textareaType={3}
									maxWordsLength={120}
									disabled={
										initiativeMaturityLevel !== RADIOBUTTONS_VALUES.initiativeMaturityLevel.first &&
										initiativeMaturityLevel !==
											RADIOBUTTONS_VALUES.initiativeMaturityLevel.second &&
										initiativeMaturityLevel !== RADIOBUTTONS_VALUES.initiativeMaturityLevel.third &&
										initiativeMaturityLevel !== RADIOBUTTONS_VALUES.initiativeMaturityLevel.fourth
									}
									clearValue={
										initiativeMaturityLevel !== RADIOBUTTONS_VALUES.initiativeMaturityLevel.first &&
										initiativeMaturityLevel !==
											RADIOBUTTONS_VALUES.initiativeMaturityLevel.second &&
										initiativeMaturityLevel !== RADIOBUTTONS_VALUES.initiativeMaturityLevel.third &&
										initiativeMaturityLevel !== RADIOBUTTONS_VALUES.initiativeMaturityLevel.fourth
									}
								/>
								<span
									style={{
										fontSize: '1.3em',
										paddingRight: '0.5vw',
										color:
											wordCount(this.props.values['initiativeTimeEstimation']) > 120 ? 'red' : '',
									}}
								>
									{wordCount(this.props.values['initiativeTimeEstimation'])} / 120
								</span>
							</div>
						</div>
						<div className={css.fieldsWrapper}>
							<div className={css.fieldWrapper}>
								<Radio
									name="initiativeMaturityLevel"
									value={RADIOBUTTONS_VALUES.initiativeMaturityLevel.fifth}
									label="היוזמה בתהליך"
									error={initiativeMaturityLevelError}
								/>
							</div>
							<div
								className={classNames(
									css.fieldWrapper,
									initiativeTimeEstimationInProgress &&
										initiativeMaturityLevel === RADIOBUTTONS_VALUES.initiativeMaturityLevel.fifth &&
										css.error
								)}
							>
								<TextField
									name="initiativeTimeEstimationInProgress"
									placeholder={PLACEHOLDERS_TEXTS.initativeStart}
									isTextarea
									textareaType={1}
									disabled={
										initiativeMaturityLevel !== RADIOBUTTONS_VALUES.initiativeMaturityLevel.fifth
									}
									clearValue={
										initiativeMaturityLevel !== RADIOBUTTONS_VALUES.initiativeMaturityLevel.fifth
									}
									maxWordsLength={120}
								/>
								<span
									style={{
										fontSize: '1.3em',
										paddingRight: '0.5vw',
										color:
											wordCount(this.props.values['initiativeTimeEstimationInProgress']) > 120
												? 'red'
												: '',
									}}
								>
									{wordCount(this.props.values['initiativeTimeEstimationInProgress'])} / 120
								</span>
							</div>
						</div>
					</div>
					<div className={classNames(css.formRow, css.executorRow)}>
						<div className={css.rowTitle}>מי יוציא לפועל? (הצוות) </div>
						<div className={css.fieldsWrapper}>
							<div
								className={classNames(
									css.fieldWrapper,
									css.textField,
									personDescriptionError && css.error
								)}
							>
								<TextField
									name="personDescription"
									placeholder={PLACEHOLDERS_TEXTS.personDescription}
									isTextarea
									textareaType={1}
									maxWordsLength={120}
								/>
								<span
									style={{
										fontSize: '1.3em',
										paddingRight: '0.5vw',
										color: wordCount(this.props.values['personDescription']) > 120 ? 'red' : '',
									}}
								>
									{wordCount(this.props.values['personDescription'])} / 120
								</span>
							</div>
						</div>
					</div>
					<div className={css.formRow}>
						<div className={css.rowTitle}>השפעה ותרומה</div>
						<div className={classNames(css.radioBtnsWrapper, css.radioBtnsWrapperWithTitle)}>
							<div className={css.radioBtnsWrapperTitle}>
								עד כמה תשפיע היוזמה לדעתך על איכות החיים של החולים בהתמודדות עם מצבם הרפואי?
							</div>
							<div className={css.fieldWrapper}>
								<Radio
									name="initiativeWillEffectTheLife"
									value={RADIOBUTTONS_VALUES.initiativeWillEffectTheLife.first}
									label="במידה מועטה"
									error={initiativeWillEffectTheLifeError}
								/>
							</div>
							<div className={css.fieldWrapper}>
								<Radio
									name="initiativeWillEffectTheLife"
									value={RADIOBUTTONS_VALUES.initiativeWillEffectTheLife.second}
									label="במידה בינונית"
									error={initiativeWillEffectTheLifeError}
								/>
							</div>
							<div className={css.fieldWrapper}>
								<Radio
									name="initiativeWillEffectTheLife"
									value={RADIOBUTTONS_VALUES.initiativeWillEffectTheLife.third}
									label="במידה רבה"
									error={initiativeWillEffectTheLifeError}
								/>
							</div>
						</div>
						<div className={classNames(css.radioBtnsWrapper, css.radioBtnsWrapperWithTitle)}>
							<div className={css.radioBtnsWrapperTitle}>
								עד כמה תשפיע היוזמה לדעתך על מערכת הבריאות ו/או העוסקים בה?
							</div>
							<div className={css.fieldWrapper}>
								<Radio
									name="initiativeWillEffectTheHealth"
									value={RADIOBUTTONS_VALUES.initiativeWillEffectTheHealth.first}
									label="במידה מועטה"
									error={initiativeWillEffectTheHealthError}
								/>
							</div>
							<div className={css.fieldWrapper}>
								<Radio
									name="initiativeWillEffectTheHealth"
									value={RADIOBUTTONS_VALUES.initiativeWillEffectTheHealth.second}
									label="במידה בינונית"
									error={initiativeWillEffectTheHealthError}
								/>
							</div>
							<div className={css.fieldWrapper}>
								<Radio
									name="initiativeWillEffectTheHealth"
									value={RADIOBUTTONS_VALUES.initiativeWillEffectTheHealth.third}
									label="במידה רבה"
									error={initiativeWillEffectTheHealthError}
								/>
							</div>
						</div>
						<div className={classNames(css.radioBtnsWrapper, css.radioBtnsWrapperWithTitle)}>
							<div className={css.radioBtnsWrapperTitle}>
								עד כמה לדעתך היוזמה תורמת לקיימות מערכת הבריאות בהיבט של גישור בין הצרכים הנוכחיים
								והעתידיים של המערכת לבין מקורות המימון המוגבלים שלה?
							</div>
							<div className={css.fieldWrapper}>
								<Radio
									name="initiativeWillHelpBridge"
									value={RADIOBUTTONS_VALUES.initiativeWillHelpBridge.first}
									label="במידה מועטה"
									error={initiativeWillHelpBridgeError}
								/>
							</div>
							<div className={css.fieldWrapper}>
								<Radio
									name="initiativeWillHelpBridge"
									value={RADIOBUTTONS_VALUES.initiativeWillHelpBridge.second}
									label="במידה בינונית"
									error={initiativeWillHelpBridgeError}
								/>
							</div>
							<div className={css.fieldWrapper}>
								<Radio
									name="initiativeWillHelpBridge"
									value={RADIOBUTTONS_VALUES.initiativeWillHelpBridge.third}
									label="במידה רבה"
									error={initiativeWillHelpBridgeError}
								/>
							</div>
						</div>
					</div>
					<div className={classNames(css.formRow, css.mediaFields)}>
						<div className={css.rowTitle}>חומרים תומכים</div>
						<div className={css.mediaFieldsUploadInfo}>
							מומלץ מאוד לצרף חומרים תומכים [עד 3 קבצים/מצגות/ במסמכים בפורמט <span>PDF</span> או{' '}
							<span>WORD</span>, רצוי לצרף תמונה איכותית של המגיש/ים. גודל מקסימלי לכל החומרים: 10
							<span>MB</span>]
						</div>
						<div className={classNames(css.mediaFieldsWrapper)}>
							<div className={css.fieldWrapper}>
								<MediaField
									name="file1"
									placeholder="1"
									sizeLimit={10 * 1024 * 1024 - fileSize2 + fileSize3}
									onSetFileSize={this.onSetFileSize1}
									onShowSizeLimitPopup={this.showFilesSizeLimitPopup}
								/>
							</div>
							<div className={css.fieldWrapper}>
								<MediaField
									name="file2"
									placeholder="2"
									sizeLimit={10 * 1024 * 1024 - fileSize1 + fileSize3}
									onSetFileSize={this.onSetFileSize2}
									onShowSizeLimitPopup={this.showFilesSizeLimitPopup}
								/>
							</div>
							<div className={css.fieldWrapper}>
								<MediaField
									name="file3"
									placeholder="3"
									sizeLimit={10 * 1024 * 1024 - fileSize1 + fileSize2}
									onSetFileSize={this.onSetFileSize3}
									onShowSizeLimitPopup={this.showFilesSizeLimitPopup}
								/>
							</div>
						</div>
					</div>
					<div className={classNames(css.formRow)}>
						<div className={css.rowTitle}>
							קישורים לחומרים חיצוניים <span>(אתר, סרטון, מאמר, פרסום וכד')</span>
						</div>
						<div className={classNames(css.fieldWrapper, css.middleField)}>
							<TextField name="externalLink1" placeholder={PLACEHOLDERS_TEXTS.externalLink1} inlineType />
						</div>
						<div className={classNames(css.fieldWrapper, css.middleField)}>
							<TextField name="externalLink2" placeholder={PLACEHOLDERS_TEXTS.externalLink2} inlineType />
						</div>
						<div className={classNames(css.fieldWrapper, css.middleField)}>
							<TextField name="externalLink3" placeholder={PLACEHOLDERS_TEXTS.externalLink3} inlineType />
						</div>
					</div>
					<div className={classNames(css.formRow, css.withCheckboxes)}>
						<div className={css.checkboxWrapper}>
							<Checkbox name="checkbox1" label={CHECKBOXES_LABELS.checkbox1} error={checkbox1Error} />
						</div>
						<div className={css.checkboxWrapper}>
							<Checkbox name="checkbox2" label={CHECKBOXES_LABELS.checkbox2} error={checkbox2Error} />
						</div>
					</div>
					<div id="recaptcha-container" className={css.captcha} />
					<div className={classNames(css.formRow, css.withButton)}>
						{this.state.isLoading && <Icon type="preloader" className={css.preloader} />}
						<Button className={css.submitBtn} label={this.state.isLoading ? '' : 'שלח'} type="submit" />
					</div>
				</Form>

				<ErrorsPopup
					className={!isUserNotified && !_.isEmpty(neededErrorsList) ? 'show' : ''}
					closePopup={this.closeErrorPopup}
					errors={neededErrorsList}
				/>
				<SendedFormPopup className={isShowSenededFormPopup ? 'show' : ''} />
				<AlreadyInSystemPopup className={isShowAlreadyInSystemPopup ? 'show' : ''} />
				<FilesSizeLimitPopup
					className={isShowFilesSizeLimitPopup ? 'show' : ''}
					closePopup={this.closeFilesSizeLimitPopup}
				/>
			</div>
		);
	}
}

const mapState = (state: ClientStore) => {
	const values = 'form.userInfo.values';
	const errors = 'form.userInfo.submitErrors';

	return {
		values: _.get(state, `${values}`, {}),
		errors: _.get(state, `${errors}`, {}),
	};
};

const mapDispatch = {
	initializeForm: initialize,
	createItem,
};

export default connect(mapState, mapDispatch)(ContentForm);
