// @flow
import * as React from 'react';
import classNames from 'classnames';
import * as css from './AlreadyInSystem.scss';

type Props = { className?: string };

const AlreadyInSystemPopup = ({ className }: Props) => {
	return (
		<div className={classNames(css.alreadyInSystem, className)}>
			<div className={classNames(css.alreadyInSystemWrapper)}>
				<p>
					<span>פרטיך כבר במערכת</span>
				</p>
			</div>
		</div>
	);
};

AlreadyInSystemPopup.defaultProps = { className: '' };

export default AlreadyInSystemPopup;
