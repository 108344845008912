/* eslint-disable react/no-danger */
// @flow
import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import * as css from './Checkbox.scss';

type Props = {
	input: {
		name: string,
		onChange: Function,
		checked?: boolean,
	},
	meta: {
		touched?: boolean,
		error?: string,
		submitFailed?: boolean,
	},
	className?: string,
	label: string,
	error?: string,
};

export default class Checkbox extends React.PureComponent<Props> {
	static defaultProps = {
		className: '',
		error: '',
	};

	render() {
		const { className, label, error } = this.props;
		const isActive = _.get(this.props, 'input.checked');

		return (
			<label className={classNames(css.checkbox, isActive && css.active, error && css.error, className)}>
				<div className={css.control} />
				<p dangerouslySetInnerHTML={{ __html: label }}></p>
				<input {...this.props.input} type="checkbox" />
			</label>
		);
	}
}
