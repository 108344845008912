/* eslint-disable react/no-array-index-key */
// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';
import * as css from './Errors.scss';

type Props = { className?: string, closePopup: Function, errors: any };

class ErrorsPopup extends React.PureComponent<Props> {
	static defaultProps = {
		className: '',
	};

	componentDidMount() {}

	clickOnCloseListener = () => {
		const { closePopup } = this.props;

		closePopup();
	};

	render() {
		const { className, errors } = this.props;
		// console.info('errors', errors);
		const errorsArr = Object.values(errors);

		return (
			<div className={classNames(css.errors, className)}>
				<div className={classNames(css.errorsWrapper)}>
					<div className={css.closeBtn} onClick={this.clickOnCloseListener}></div>
					<div className={css.errorsList}>
						{errorsArr.map((item, index) => {
							return <p dangerouslySetInnerHTML={{ __html: item }} key={index}></p>;
						})}
					</div>
				</div>
			</div>
		);
	}
}

export default ErrorsPopup;
