/* eslint-disable react/no-unescaped-entities */
// @flow
import * as React from 'react';
import classNames from 'classnames';
import * as css from './SendedForm.scss';

type Props = { className?: string };

const SendedFormPopup = ({ className }: Props) => {
	return (
		<div className={classNames(css.sendedForm, className)}>
			<div className={classNames(css.sendedFormWrapper)}>
				<p className={css.title}>!היוזמה הוגשה</p>
				<p className={css.text}>"לאחר הליך סינון ודירוג המתבצע על ידי "ידיעות אחרונות</p>
				<p className={css.text}>.ייבחרו 10 היוזמות שהגיעו לשלב הגמר</p>
				<p className={css.text}>.הודעות על כך יינתנו באופן אישי למגישים</p>
				<p className={css.text}>שאלות נוספות לגבי תהליך ההגשה ניתן להפנות</p>
				<div className={css.emailWrapper}>
					<a href="mailto:reboot.forum.il@gmail.com" className={css.email}>
						reboot.forum.il@gmail.com
					</a>{' '}
					:בדוא"ל
				</div>
				<p className={css.footerText}>!תודה על השתתפותכם, ובהצלחה</p>
			</div>
		</div>
	);
};

SendedFormPopup.defaultProps = { className: '' };

export default SendedFormPopup;
