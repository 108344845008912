// @flow

import * as React from 'react';
import classNames from 'classnames';

import { SHARE_CONSTANTS } from 'client/constants';
import { share } from 'client/utils/share';
import { dataLayerEvents } from 'common/utils/gtag';

import { Icon } from '../Icon';

import * as css from './Socials.scss';

type Props = {
	className?: string,
};

type State = {
	isOpen: boolean,
};

export default class Socials extends React.Component<Props, State> {
	static defaultProps = {
		className: '',
	};

	state = {};

	onShare = (e: SyntheticEvent<HTMLButtonElement>) => {
		const type = e.currentTarget.id;

		dataLayerEvents.onShareBtnClick(type);
		share(type);
	};

	render() {
		const { className } = this.props;

		return (
			<div className={classNames(css.socials, className)}>
				<div className={css.overflowWrap}>
					<div className={css.btnsWrap}>
						<button
							type="button"
							className={css.whatsAppBtn}
							id={SHARE_CONSTANTS.WHATSAPP}
							onClick={this.onShare}
						>
							<Icon type="whatsapp" width={32} height={32} />
						</button>
						<button
							type="button"
							className={css.facebookBtn}
							id={SHARE_CONSTANTS.FACEBOOK}
							onClick={this.onShare}
						>
							<Icon type="facebook" width={32} height={32} />
						</button>
					</div>
				</div>
			</div>
		);
	}
}
