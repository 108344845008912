/* eslint-disable react/no-danger */
// @flow
import * as React from 'react';
import classNames from 'classnames';
import logo1Src from 'assets/client/images/footer-logos/knowledge.png';
import logo2Src from 'assets/client/images/footer-logos/ynet.png';
import logo3Src from 'assets/client/images/footer-logos/info.png';
import logo4Src from 'assets/client/images/footer-logos/association.png';
import logo5Src from 'assets/client/images/footer-logos/israeli-community.png';
import logo6Src from 'assets/client/images/footer-logos/aoa.png';
import logo7Src from 'assets/client/images/footer-logos/rcpr.png';
// import logo8Src from 'assets/client/images/footer-logos/barry.png';
import logo9Src from 'assets/client/images/footer-logos/novolog.png';
// import logo10Src from 'assets/client/images/footer-logos/sanofi.png';
import logo11Src from 'assets/client/images/footer-logos/abbvie.png';
import yitLogoSrc from 'assets/client/images/yit_logo.svg';
import amChamSrc from 'assets/client/images/footer-logos/AmCham.png';
import shohamSrc from 'assets/client/images/footer-logos/Shoham.png';
import * as css from './Footer.scss';

type Props = {
	className?: string,
	footerTextOne?: string,
	footerTextTwo?: string,
};

const Footer = ({ className, footerTextOne, footerTextTwo }: Props) => {
	const termOfUse = 'תנאי שימוש';
	const termOfUseLink = 'https://z.ynet.co.il/short/content/2018/privacypolicy/terms.html';
	const privacyPolicy = 'מדיניות ופרטיות';
	const privacyPolicyLink = 'https://z.ynet.co.il/short/content/2018/privacypolicy/policy.html';
	const yit = 'עיצוב, אפיון ופיתוח';
	const yitLink = 'https://yit.co.il/';

	return (
		<footer className={classNames(css.footer, className)}>
			<div className={css.footerTop}>
				<div className={css.footerTopWrapper}>
					<div className={css.logosTitles}>
						<p>בתמיכה, ליווי ושיתוף:</p>
						<p>:Made Possible By</p>
					</div>
					<div className={css.logos}>
						<div className={css.logoWrapper}>
							<img src={logo1Src} alt="" />
						</div>
						<div className={css.logoWrapper}>
							<img src={logo2Src} alt="" />
						</div>
						<div className={css.logoWrapper}>
							<img src={logo3Src} alt="" />
						</div>
						<div className={css.logoWrapper}>
							<img src={logo4Src} alt="" />
						</div>
						<div className={css.logoWrapper}>
							<img src={logo5Src} alt="" />
						</div>
						<div className={css.logoWrapper}>
							<img src={logo6Src} alt="" />
						</div>
						<div className={css.logoWrapper}>
							<img src={logo7Src} alt="" />
						</div>
						{/* <div className={css.logoWrapper}>
							<img src={logo8Src} alt="" />
						</div> */}
						<div className={css.logoWrapper}>
							<img src={amChamSrc} alt="" />
						</div>
						<div className={css.logoWrapper}>
							<img src={shohamSrc} alt="" />
						</div>
						<div className={css.logoWrapper}>
							<img src={logo9Src} alt="" />
						</div>
						{/* <div className={css.logoWrapper}>
							<img src={logo10Src} alt="" />
						</div> */}
						<div className={css.logoWrapper}>
							<img src={logo11Src} alt="" />
						</div>
					</div>
					<div className={css.footerTextOne} dangerouslySetInnerHTML={{ __html: footerTextOne }}></div>
					<div className={css.footerTextTwo} dangerouslySetInnerHTML={{ __html: footerTextTwo }}></div>
				</div>
			</div>
			<div className={css.footerLinks}>
				<div className={css.footerLinksWrapper}>
					<div className={css.privacyAndTerms}>
						<a href={termOfUseLink} target="_blank" rel="noopener noreferrer">
							{termOfUse}
						</a>
						<a href={privacyPolicyLink} target="_blank" rel="noopener noreferrer">
							{privacyPolicy}
						</a>
					</div>
					<div className={css.yit}>
						<a href={yitLink} target="_blank" rel="noopener noreferrer">
							<span>{yit}</span>
							<img src={yitLogoSrc} alt="yit-logo" />
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

Footer.defaultProps = {
	className: '',
	footerTextOne: '',
	footerTextTwo: '',
};

export { Footer };
