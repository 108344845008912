/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as css from './Icon.scss';

const Icon = ({ type, className, wrapInSquare }) => {
	let Content;

	switch (type) {
		case 'preloader':
			Content = (
				<svg viewBox="25 25 50 50">
					<circle cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
				</svg>
			);
			break;

		default:
			break;
	}

	return <div className={classNames(wrapInSquare && css.icon, className)}>{Content}</div>;
};

Icon.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string.isRequired,
	wrapInSquare: PropTypes.bool,
};

Icon.defaultProps = {
	className: '',
	wrapInSquare: false,
};

export default Icon;
