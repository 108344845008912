// @flow
import * as React from 'react';
import { Field } from 'redux-form';

import Input from './MediaField';

type Props = {
	className?: string,
	name: string,
	placeholder?: string,
	disabled?: boolean,
	onlyFileUpload?: boolean,
	onSetFileSize?: Function,
	onShowSizeLimitPopup?: Function,
};

export default class MediaField extends React.PureComponent<Props> {
	static defaultProps = {
		className: '',
		placeholder: '',
		disabled: false,
		onlyFileUpload: false,
		onSetFileSize: () => {},
		onShowSizeLimitPopup: () => {},
	};

	render() {
		const {
			className,
			name,
			placeholder,
			disabled,
			onlyFileUpload,
			onSetFileSize,
			onShowSizeLimitPopup,
		} = this.props;
		const InputComponent = props => (
			<Input
				{...props}
				className={className}
				placeholder={placeholder}
				onlyFileUpload={onlyFileUpload}
				disabled={disabled}
				onSetFileSize={onSetFileSize}
				onShowSizeLimitPopup={onShowSizeLimitPopup}
			/>
		);

		return <Field name={name} component={InputComponent} />;
	}
}
