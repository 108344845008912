/* eslint-disable max-len */
// @flow
import * as React from 'react';
import classNames from 'classnames';
import * as css from './ClosedActivity.scss';

type Props = { className?: string };

const ClosedActivityPopup = ({ className }: Props) => {
	return (
		<div className={classNames(css.closedActivity, className)}>
			<div className={classNames(css.closedActivityWrapper)}>
				<p className={css.forDesk}>
					<span>
						תודה על הרצון לקחת חלק בתחרות. <br /> ההרשמה לתחרות הסתיימה.
					</span>
				</p>
				<p className={css.forMobile}>
					<span>
						תודה על הרצון לקחת חלק בתחרות. <br /> ההרשמה לתחרות הסתיימה.
					</span>
				</p>
			</div>
		</div>
	);
};

ClosedActivityPopup.defaultProps = { className: '' };

export default ClosedActivityPopup;
