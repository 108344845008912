// @flow

/* eslint-disable max-len,react/jsx-tag-spacing */
import * as React from 'react';
import classNames from 'classnames';
import * as css from './Icon.scss';

type Props = {|
	type: string,
	className?: string,
	width?: number,
	height?: number,
	color?: string,
|};

/**
 * @link - https://material.io/tools/icons/?style=baseline
 * @return {XML}
 */
const Icon = ({ type, className, width, height, color, ...iconProps }: Props) => {
	let icon = null;
	const svgProps = {
		width,
		height,
		viewBox: `0 0 ${width || 24} ${height || 24}`,
		xmlns: 'http://www.w3.org/2000/svg',
	};

	switch (type) {
		case 'plus':
			icon = (
				<svg {...svgProps}>
					<g id="Group_1244" data-name="Group 1244" transform="translate(-234.5 -597.5)">
						<rect
							id="Rectangle_391"
							data-name="Rectangle 391"
							className="cls-1"
							width="4"
							height="24"
							transform="translate(244.5 597.5)"
							fill="#468aff"
						/>
						<rect
							id="Rectangle_392"
							data-name="Rectangle 392"
							className="cls-1"
							width="24"
							height="4"
							transform="translate(234.5 607.5)"
							fill="#468aff"
						/>
					</g>
				</svg>
			);
			break;
		case 'twitter':
			icon = (
				<svg {...svgProps}>
					<g id="twitter" transform="translate(0.4 -0.1)">
						<g id="Group_1785" data-name="Group 1785" transform="translate(0 0)">
							<ellipse
								id="Ellipse_13537"
								data-name="Ellipse 13537"
								className="cls-1"
								cx="20.5"
								cy="20"
								rx="20.5"
								ry="20"
								transform="translate(-0.4 0.1)"
								fill="#64a3df"
							/>
						</g>
						<path
							id="Icon_awesome-twitter"
							data-name="Icon awesome-twitter"
							className="cls-2"
							d="M18.423,7.537c.013.182.013.365.013.547A11.891,11.891,0,0,1,6.462,20.058,11.892,11.892,0,0,1,0,18.168a8.706,8.706,0,0,0,1.016.052,8.428,8.428,0,0,0,5.225-1.8A4.216,4.216,0,0,1,2.306,13.5a5.307,5.307,0,0,0,.795.065,4.451,4.451,0,0,0,1.107-.143A4.209,4.209,0,0,1,.834,9.3V9.244a4.238,4.238,0,0,0,1.9.534,4.215,4.215,0,0,1-1.3-5.628,11.962,11.962,0,0,0,8.677,4.4,4.751,4.751,0,0,1-.1-.964A4.212,4.212,0,0,1,17.289,4.71,8.285,8.285,0,0,0,19.96,3.693a4.2,4.2,0,0,1-1.85,2.319,8.436,8.436,0,0,0,2.423-.651,9.046,9.046,0,0,1-2.111,2.176Z"
							transform="translate(10.025 8.426)"
							fill="#fff"
						/>
					</g>
				</svg>
			);
			break;
		case 'whatsapp':
			icon = (
				<svg {...svgProps}>
					<path
						fill="#466ba4"
						d="M14249 6987a16 16 0 1 1 16 16 16 16 0 0 1-16-16zm6.763-.266a9.56 9.56 0 0 0 1.28 4.789l-1.358 4.96 5.074-1.333a9.6 9.6 0 0 0 4.577 1.168 9.577 9.577 0 1 0-9.577-9.584zm9.573 7.964a7.949 7.949 0 0 1-4.05-1.107l-.29-.172-3.015.789.807-2.936-.19-.3a7.96 7.96 0 1 1 6.742 3.728zm-4.024-12.082a2.674 2.674 0 0 0-.835 1.993 4.678 4.678 0 0 0 .975 2.477 10.7 10.7 0 0 0 4.089 3.609 13.637 13.637 0 0 0 1.362.505 3.233 3.233 0 0 0 1.509.093 2.452 2.452 0 0 0 1.613-1.136 1.981 1.981 0 0 0 .14-1.136c-.062-.1-.219-.161-.459-.279s-1.416-.7-1.635-.778-.38-.122-.537.118-.62.778-.756.939-.28.179-.521.058a6.489 6.489 0 0 1-1.924-1.187 7.162 7.162 0 0 1-1.334-1.656c-.136-.24 0-.358.107-.487a6.871 6.871 0 0 0 .6-.821.439.439 0 0 0-.021-.419c-.057-.118-.537-1.294-.738-1.774-.172-.412-.348-.412-.487-.412h-.05c-.14-.007-.3-.007-.459-.007a.88.88 0 0 0-.64.3z"
						transform="translate(-14248.999 -6971)"
					/>
				</svg>
			);
			break;
		case 'facebook':
			icon = (
				<svg {...svgProps}>
					<path
						fill="#466ba4"
						d="M16 .349a15.991 15.991 0 0 0-2.668 31.76V19.687h-3.86v-4.47h3.86v-3.3c0-3.824 2.336-5.908 5.748-5.908a31.853 31.853 0 0 1 3.446.175v4h-2.367c-1.855 0-2.213.881-2.213 2.175v2.853h4.427l-.577 4.47h-3.85v12.534A15.992 15.992 0 0 0 16 .349z"
						transform="translate(0 -0.349)"
					/>
				</svg>
			);
			break;

		case 'share':
			icon = (
				<svg {...svgProps}>
					<g id="share" transform="translate(0.33 -0.337)">
						<rect
							id="Rectangle_20"
							data-name="Rectangle 20"
							className="cls-1"
							width="33"
							height="32"
							transform="translate(-0.33 0.337)"
							fill="#468aff"
							opacity="0"
						/>
						<path
							id="Path_1252"
							data-name="Path 1252"
							className="cls-2"
							fill="#468aff"
							d="M23.559,19.17a4.042,4.042,0,0,0-2.83,1.159L10.085,15.586v-.9L20.729,9.926a4.042,4.042,0,1,0-1.213-2.883V7.5L8.873,12.244a4.042,4.042,0,1,0,0,5.767l10.644,4.756v.445a4.042,4.042,0,1,0,4.042-4.042Zm0-13.474a1.347,1.347,0,1,1-1.347,1.347A1.347,1.347,0,0,1,23.559,5.7ZM6.043,16.475A1.347,1.347,0,1,1,7.39,15.127,1.347,1.347,0,0,1,6.043,16.475Zm17.516,8.084a1.347,1.347,0,1,1,1.347-1.347A1.347,1.347,0,0,1,23.559,24.559Z"
							transform="translate(0.694 1.041)"
						/>
					</g>
				</svg>
			);
			break;
		case 'accessibility':
			icon = (
				<svg {...svgProps}>
					<g id="Group_338" transform="translate(-18 -24)">
						<rect
							id="Rectangle_299"
							width="34"
							height="34"
							fill="#cedb6e"
							rx="17"
							transform="translate(18 24)"
						/>
						<g id="XMLID_28_" transform="translate(-19.379 32)">
							<path
								fill="#476ba3"
								id="XMLID_31_"
								d="M178.532 3.418a1.709 1.709 0 1 0-1.709-1.709 1.709 1.709 0 0 0 1.709 1.709z"
								transform="translate(-124.881)"
							/>
							<path
								fill="#476ba3"
								id="XMLID_30_"
								d="M56.292 204.681a3.777 3.777 0 1 1-5.149-4.5v-1.6a5.281 5.281 0 1 0 6.313 7.269l-.6-1.172z"
								transform="translate(0 -191.581)"
							/>
							<path
								fill="#476ba3"
								id="XMLID_29_"
								d="M191.737 128.291l-2.289-4.5a1 1 0 0 0-.894-.549h-2.931v-.668h2.523a.789.789 0 0 0 .753-.753.752.752 0 0 0-.753-.753h-2.523V118.8a1.5 1.5 0 1 0-3.01 0v4.912a1.535 1.535 0 0 0 1.535 1.535h3.791l2.01 3.952a1 1 0 1 0 1.788-.91z"
								transform="translate(-130.467 -113.162)"
							/>
						</g>
					</g>
				</svg>
			);
			break;
		default:
			break;
	}

	return (
		<i className={classNames(css.icon, className)} {...iconProps}>
			{icon}
		</i>
	);
};

Icon.defaultProps = {
	className: '',
	width: 24,
	height: 24,
	color: '#111',
};

export { Icon };
